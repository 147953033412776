import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ac00722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-project-category" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      ref: "selectElement",
      class: "project-select",
      name: "category",
      id: "category",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitSelection && _ctx.emitSelection(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectCategories, (category) => {
        return (_openBlock(), _createElementBlock("option", {
          key: category,
          value: category
        }, _toDisplayString(_ctx.tr(category.title).value), 9, _hoisted_2))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.selected]
    ])
  ]))
}