
import { computed, defineComponent, inject, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props:{
    nom:String
  },
 data(){
    return {
      activeTab:0 as number,
    } 
  },
  methods: {
    selectTab(index: number) {
        this.activeTab=index;
    }
  },

  setup(){
    const store = useStore();
    const skills = computed(()=> store.getters.skills);
    onMounted(()=>{
      store.dispatch("FetchSkills", 1);
    })
    const tr = inject('tr');
    return {
      skills,
      tr
    }
  }
  
});

