
import { Options, Vue } from 'vue-class-component';
import MainPage from '@/components/MainPage.vue'; // @ is an alias to /src

@Options({
  components: {
    MainPage,
  },
})
export default class App extends Vue {
  setup() {
      /*
      useSanityClient({
        projectId: 'bu9errtr',
        dataset: 'production',
        useCdn: process.env.NODE_ENV === 'production',
      })
      */

    }
  
}
