
import ImpossibleGeometry3D from './helper/ImpossibleGeometry3D.vue';
import {useStore} from 'vuex';
import { computed, onMounted } from '@vue/runtime-core';

export default{
  props:{
    nom:String,
    description:Array
  },
  components:{
    ImpossibleGeometry3D
  },
  setup(){
    const store = useStore();
    const section = computed(()=> store.getters.section);
    onMounted(()=>{
      store.dispatch("FetchSection", 1);
    })
    return {
      section
    }
  }

}

