
import ProjectsSection from "./ProjectsSection.vue";
import Contact from "./Contact.vue";
import PresentationSection from "./PresentationSection.vue";
import SkillsSection from "./SkillsSection.vue";
import {
  ref,
  onBeforeUnmount,
  defineComponent,
  onMounted,
  computed,
  ComputedRef,
  inject,
  Ref,
} from "vue";
import { useStore } from "vuex";
import { Content, Link, LocaleString, Section } from "@/store";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default defineComponent({
  props: {},
  components: {
    PresentationSection,
    Contact,
    ProjectsSection,
    SkillsSection,
  },
  data() {
    return {
      inMove: false,
      offsets: [] as number[],
      windowTop: 0,
    };
  },
  methods: {
    redirectToFrench() {
      this.$router.push({ path: "/fr" });
    },
    redirectToDefault() {
      this.$router.push({ path: "/" });
    },
    getDescriptions() {
      return this.content?.description?.map(
        (description: LocaleString) => (this.tr as any)(description).value
      );
    },
    getSections() {
      if (this.section) {
        return [
          (this.tr as any)(this.section.about).value,
          (this.tr as any)(this.section.project).value,
          (this.tr as any)(this.section.skill).value,
          (this.tr as any)(this.section.contact).value,
        ];
      }
      return ["About", "Projets", "Skills", "Contact"];
    },
    openUrl() {
      window.open(this.link.cv, "_blank")?.focus();
    },
    getSectionIndex() {
      const sectionOffsets = this.offsets;
      const scrollY = this.windowTop;
      console.log("offsets",sectionOffsets, scrollY);
      for (let i = 0; i < sectionOffsets.length; i++) {
        if (sectionOffsets[i] > scrollY) {
          return i - 1;
        }
      }
      return sectionOffsets.length - 1;
    },
    getSectionsOffets(){
      const sections: (HTMLElement | null)[] = [
        this.aboutSection,
        this.projectsSection,
        this.skillsSection,
        this.contactSection,
      ].filter((section) => section); // Filter out null values

      // Calculate offsets only if all refs are defined
      if (sections.every((section) => section)) {
        return sections.map(
          (section) => (section?.offsetTop || 0) - 100
        );
      }else{
        return [];
      }
    },
    scrollToSection(id: number, force = false) {
      if (this.inMove && !force) return false;

      const sections = [
        this.aboutSection,
        this.projectsSection,
        this.skillsSection,
        this.contactSection,
      ];

      this.inMove = true;
      sections[id]?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        this.inMove = false;
      }, 600);
    },
  },
  mounted() {

    const calculateSectionOffsets = () => {
      // Ensure that refs are defined before accessing their properties
      const sections: (HTMLElement | null)[] = [
        this.aboutSection,
        this.projectsSection,
        this.skillsSection,
        this.contactSection,
      ].filter((section) => section); // Filter out null values

      // Calculate offsets only if all refs are defined
      if (sections.every((section) => section)) {
        this.offsets =  sections.map(
          (section) => (section?.offsetTop || 0) - 100
        );
      }else{
        return [];
      }
    };
    const onScroll = (e: any) => {
      this.windowTop = window?.top?.scrollY as number;
      calculateSectionOffsets();
    };
    calculateSectionOffsets();
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", calculateSectionOffsets);

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", calculateSectionOffsets);
    });
  },
  setup() {
    const store = useStore();
    const section: ComputedRef<Section> = computed(() => store.getters.section);
    const content: ComputedRef<Content> = computed(() => store.getters.content);
    const link: ComputedRef<Link> = computed(() => store.getters.link);
    onMounted(() => {
      store.dispatch("FetchSection", 1);
      store.dispatch("FetchContent", 1);
      store.dispatch("FetchLink", 1);
    });
    const tr = inject("tr");

    const aboutSection: Ref<HTMLElement | null> = ref(null);
    const projectsSection: Ref<HTMLElement | null> = ref(null);
    const skillsSection: Ref<HTMLElement | null> = ref(null);
    const contactSection: Ref<HTMLElement | null> = ref(null);

    return {
      section,
      content,
      link,
      tr,
      aboutSection,
      projectsSection,
      skillsSection,
      contactSection,
    };
  },
});
