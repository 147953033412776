


import { Project, ProjectCategory } from '@/store';
import { computed, ComputedRef, defineComponent, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import RadioSelection from "./helper/RadioSelection.vue";
import CardProject from './helper/CardProject.vue';

export default defineComponent({
  props:{
    nom:String
  },
  components:{
    RadioSelection,
    CardProject
  },
    watch: {
    projectCategories: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selectedOption = this.projectCategories[0];
        }
      }
    }
  },
 data(){
    return {
      activeTab:0 as number,
    } 
  },
  methods: {
    selectTab(index: number) {
        this.activeTab=index;
    },
    getProjectCategories(){
      return this.projectCategories;
    },
    isEqual(obj1?: ProjectCategory, obj2?: ProjectCategory){
      return obj1?.title?.en == obj2?.title?.en;
    },
    getCurrentProjects(): Project[]{
      const selectedProject = this.projects.filter((project: Project) => this.isEqual(project.category,this.selectedOption));
      if (selectedProject.length > 0) {
        return selectedProject;
      }else{
        const defaultCategory = this.projectCategories[0];
        const defaultProjects = this.projects.filter((project: Project) => this.isEqual(project.category, defaultCategory));
        if (defaultProjects.length > 0) {
          return defaultProjects;
        }else{
          return [];
        }
      }
    }
  },
  showProject(index:number) {
    this.activeTab = index;
  },
  setup(){
    const store = useStore();
    const projectCategories: ComputedRef<ProjectCategory[]> = computed(()=> store.getters.projectCategories);
    const projects = computed(()=> store.getters.projects);
    const tr = inject('tr');
    const selectedOption = ref();

    const handleSelection = (selected:ProjectCategory) => {
      selectedOption.value = selected;
    };
    onMounted(()=>{
      store.dispatch("FetchProjectCategories", 1);
      store.dispatch("FetchProjects", 1);
    })
    return {
      projectCategories,
      projects,
      tr,
      selectedOption,
      handleSelection
    }
  }
  

});


