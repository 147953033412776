
import { defineComponent } from '@vue/runtime-core';
import { inject } from 'vue';
export default defineComponent({
  props:['project'],
  setup(){
    const tr = inject('tr');
    return {
      tr
    }
  }
});

