
import { ProjectCategory } from '@/store';
import { tr } from '@/utils/utils';
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: ['projectCategories'],
  methods: {
    getProjectsName() {
      return this.projectCategories.map((category: ProjectCategory) => tr(category.title).value);
    },
    emitSelection() {
      this.$emit('selection', this.selected);
    },
  },
  data() {
    return {
      selected: ''
    };
  },
  setup() {
    const tr = inject("tr");
    return {
      tr,
    };
  },
  watch: {
    projectCategories: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selected = this.projectCategories[0];
        }
      }
    }
  }
});
