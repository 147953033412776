import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fee3e098"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "maindiv" }
const _hoisted_2 = { class: "content-inner" }
const _hoisted_3 = { class: "tabcontent" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "test" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.nom), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: skill }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.tr(skill.title).value), 1),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skill.skills, (techno) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "img-skill-container",
                key: techno.title.en
              }, [
                _createElementVNode("img", {
                  class: "img-skill",
                  src: techno.image || techno.url
                }, null, 8, _hoisted_4),
                _createElementVNode("h5", _hoisted_5, _toDisplayString(techno.title), 1)
              ]))
            }), 128))
          ])
        ], 64))
      }), 128))
    ])
  ]))
}