
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    nom:String,
    text:String,
    mail:String,
  },
})
export default class Contact extends Vue {
}
