
import { defineComponent } from "@vue/runtime-core";
import { inject } from "vue";
import ModalProject from "./ModalProject.vue";
export default defineComponent({
  props: ["project"],
  components: {
    ModalProject,
  },
  data: function () {
    return {
      showModal: false,
    };
  },
  methods: {
    redirectToLive: function () {
      window.open(this.project.urlLive, "_blank");
    },
    redirectToCode: function () {
      window.open(this.project.urlCode, "_blank");
    },
  },
  setup() {
    const tr = inject("tr");
    return {
      tr,
    };
  },
});
